import { render, staticRenderFns } from "./CoreBlockNavItem.vue?vue&type=template&id=4de1c483"
import script from "./CoreBlockNavItem.vue?vue&type=script&lang=js"
export * from "./CoreBlockNavItem.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockNavItem.vue?vue&type=style&index=0&id=4de1c483&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreIconDownArrow: require('/usr/src/app/Core.Library/src/components/icon/CoreIconDownArrow.vue').default,CoreBlockNavItem: require('/usr/src/app/Core.Library/src/components/block/CoreBlockNavItem.vue').default})
