import { render, staticRenderFns } from "./CoreBlockMainNav.vue?vue&type=template&id=266df775"
import script from "./CoreBlockMainNav.vue?vue&type=script&lang=js"
export * from "./CoreBlockMainNav.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockMainNav.vue?vue&type=style&index=0&id=266df775&prod&lang=scss"
import style1 from "./CoreBlockMainNav.vue?vue&type=style&index=1&id=266df775&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreIconMenuClose: require('/usr/src/app/Core.Library/src/components/icon/CoreIconMenuClose.vue').default,CoreIconHamburgerMenu: require('/usr/src/app/Core.Library/src/components/icon/CoreIconHamburgerMenu.vue').default,CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default,CoreBlockNavItem: require('/usr/src/app/Core.Library/src/components/block/CoreBlockNavItem.vue').default})
