import { ref, onMounted, onBeforeUnmount } from "vue";

export default function useIsMobile() {
	const breakpoint = 992; // corresponds to lg breakpoint in assets/scss/mixins
	const isMobile = ref(false);
	const windowWidth = ref(process.client ? window.innerWidth : null);

	function checkIfMobile() {
		isMobile.value = windowWidth.value < breakpoint;
	}

	function setWindowWidth() {
		windowWidth.value = window.innerWidth;
	}

	function handleResize() {
		setWindowWidth();
		checkIfMobile();
	}

	if (process.client) {
		onMounted(() => {
			handleResize();
			window.addEventListener("resize", handleResize);
		});

		onBeforeUnmount(() => {
			window.removeEventListener("resize", handleResize);
		});
	}

	return {
		isMobile,
	};
}
