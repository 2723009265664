const domain = (value) => {
	return /^((?!-)[\da-z-]+\.[a-z.]{2,6})*?$/.test(value);
};

const url = (value) => {
	return (
		/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[A-Za-z0-9\u00a1-\uffff][A-Za-z0-9\u00a1-\uffff_-]{0,62})?[A-Za-z0-9\u00a1-\uffff]\.)+(?:[A-Za-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/.test(
			value
		) || /(?:mailto?:([\w\-.+]+@[\w\-.]+\.[A-Za-z]{2,}))/.test(value)
	);
};

const httpUrl = (value) => {
	return /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/.test(
		value
	);
};

const email = (value) => {
	// eslint-disable-next-line no-control-regex
	return /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9]{2,}(?:[A-z0-9-]*[A-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-z0-9-]*[A-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
		value
	);
};

const phone = (value) => {
	return /^\d{3}-\d{3}-\d{4}$/.test(value);
};

const phoneDigitsOnly = (value) => {
	return /^\d{10}$/.test(value);
};

const lowercase = (value) => {
	return /^[a-z0-9_-]+$/.test(value);
};

const uppercase = (value) => {
	return /^[A-Z\s]+$/.test(value);
};

const digitAndPeriod = (value) => {
	return /^(\d+|\d+\.\d*)$/.test(value);
};

const isAdult = (value) => {
	var today = new Date();
	var birthDate = new Date(value);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}

	return age >= 18;
};

const isValidDate = (dateString) => {
	// First check for the pattern
	if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

	// Parse the date parts to integers
	var parts = dateString.split("/");
	var day = parseInt(parts[1], 10);
	var month = parseInt(parts[0], 10);
	var year = parseInt(parts[2], 10);

	// Check the ranges of month and year
	if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

	var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	// Adjust for leap years
	if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
		monthLength[1] = 29;

	// Check the range of the day
	return day > 0 && day <= monthLength[month - 1];
};

const wysiwygRequired = (value) => {
	return (
		value !== "" &&
		value !== undefined &&
		value !== null &&
		value !== "<p></p>"
	);
};

const regexNoSpecial = (value) => {
	return /^[a-zA-Z0-9 ]+$/.test(value);
};

const onlyAllowedCharsRegex = (value) => {
	return /^[&,.\-\u2013\w ]+$/.test(value);
};

const phoneNumberRegex = (value) => {
	return /^[0-9]{1,15}$/.test(value);
};

const regexCalendly = (value) => {
	return /^[a-z0-9_-]+$/.test(value);
};

const formatPhoneNumber = (phoneNumber) => {
	// Target 123-456-7890 or 123-456-7890 ext. 1234
	let extNumber = "";

	if (phoneNumber) {
		// Remove all non-digit characters from the input value
		phoneNumber = phoneNumber.replace(/\D/g, "");

		if (phoneNumber.length > 10) {
			extNumber = phoneNumber.slice(10, phoneNumber.length);
			phoneNumber = phoneNumber.slice(0, 10); // Limit input to 10 digits
		}

		const phoneNumberRegex = /^(\d{1,3})(\d{0,3})(\d{0,4})$/;
		phoneNumber = phoneNumber.replace(
			phoneNumberRegex,
			function (match, p1, p2, p3) {
				let formattedNumber = "";
				if (p1) {
					formattedNumber = `(${p1})`;
				}
				if (p2) {
					formattedNumber += ` ${p2}`;
				}
				if (p3) {
					formattedNumber += `-${p3}`;
				}
				return formattedNumber;
			}
		);
	}

	if (extNumber !== "") phoneNumber += " ext. " + extNumber;
	return phoneNumber;
};

const getNumbersOnlyFromPhoneFormat = (phoneNumber) => {
	if (phoneNumber) phoneNumber = phoneNumber.replace(/\D/g, "");
	return phoneNumber;
};

const zipCode = (value) => {
	return /^\d{5}?$/.test(value);
};

const zipCodeExt = (value) => {
	return /^\d{4}?$/.test(value);
};

const zipCodeFull = (value) => {
	return /(^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$)/.test(value);
};

const maxReached = (value, args) => {
	return value.length < +args.length;
};

const regexNoSpecialExceptDot = (value) => {
	return /^[a-zA-Z0-9.]+$/.test(value);
};

const alphaDashDot = (value) => {
	return /^[a-zA-Z0-9.\-_]+$/.test(value);
};

export {
	alphaDashDot,
	digitAndPeriod,
	domain,
	email,
	formatPhoneNumber,
	getNumbersOnlyFromPhoneFormat,
	httpUrl,
	isAdult,
	isValidDate,
	lowercase,
	maxReached,
	onlyAllowedCharsRegex,
	phone,
	phoneDigitsOnly,
	phoneNumberRegex,
	regexNoSpecial,
	regexNoSpecialExceptDot,
	uppercase,
	url,
	wysiwygRequired,
	zipCode,
	zipCodeExt,
	zipCodeFull,
	regexCalendly
};
