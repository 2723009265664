import { computed } from "vue";
import { useStore } from "../helpers/compositionApi";

export function useIsExpired(settings, itemCheckFunction) {
	const store = useStore();
	const { getters } = store;
	const isExpired = computed(() => {
		return getters["library/isExpired"](
			settings?.contentLibraryItems,
			itemCheckFunction
		);
	});

	return {
		isExpired,
	};
}

export function useIsPresetExpired(presetId) {
	const store = useStore();
	const { getters } = store;
	const isExpired = computed(() => {
		return getters["library/isPresetExpired"](presetId);
	});

	return {
		isExpired,
	};
}
