
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockFooterContactEmail",
	props: {
		email: {
			type: String,
			required: true,
		},
		dataObject: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const emailValue = computed(() =>
			renderData(props.email || "", props.dataObject)
		);
		return { emailValue };
	},
};
