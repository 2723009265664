
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockFooterContactAddress",
	props: {
		address: {
			type: Object,
			required: true,
		},
		dataObject: {
			type: Object,
			required: true,
		},
	},

	setup(props) {
		// TODO: Change render strategy for team vs profile
		const streetAddress = computed(() => {
			return renderData(
				props.address.streetAddress || "",
				props.dataObject
			);
		});
		const streetAddress2 = computed(() => {
			return renderData(
				props.address.streetAddress2 || "",
				props.dataObject
			);
		});
		const city = computed(() => {
			return renderData(props.address.city || "", props.dataObject);
		});
		const state = computed(() => {
			return renderData(props.address.state || "", props.dataObject);
		});
		const zip = computed(() => {
			return renderData(props.address.zip || "", props.dataObject);
		});

		const addressLabel = computed(() => {
			return renderData(props.address.label || "", props.dataObject);
		});

		const cleanAddress = () => {
			return (
				`http://maps.google.com/?q=` +
				encodeURIComponent(
					`${props.address.streetAddress} ${props.address.city} ${props.address.state} ${props.address.zip}`
				)
			);
		};
		return {
			addressLabel,
			streetAddress,
			streetAddress2,
			city,
			state,
			zip,
			cleanAddress,
		};
	},
};
