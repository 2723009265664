import { render, staticRenderFns } from "./CoreBlockFooter.vue?vue&type=template&id=6e2793ec"
import script from "./CoreBlockFooter.vue?vue&type=script&lang=js"
export * from "./CoreBlockFooter.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockFooter.vue?vue&type=style&index=0&id=6e2793ec&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockFooterDisclosures: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterDisclosures.vue').default,CoreBlockFooterBanner: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterBanner.vue').default,CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default,CoreBlockFooterSocialLinks: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterSocialLinks.vue').default,CoreBlockFooterLogo: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterLogo.vue').default,CoreBlockFooterContactGroup: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/contact/CoreBlockFooterContactGroup.vue').default,CoreBlockNavItem: require('/usr/src/app/Core.Library/src/components/block/CoreBlockNavItem.vue').default,CoreBlockFooterOneTrust: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterOneTrust.vue').default,CoreBlockFooterBackground: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterBackground.vue').default})
