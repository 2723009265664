
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";
import { formatPhoneNumber } from "@libraryHelpers/regex";

export default {
	name: "CoreBlockFooterContactPhone",
	props: {
		phone: {
			type: Object,
			required: true,
		},
		dataObject: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const phoneNumber = computed(() => {
			return formatPhoneNumber(
				renderData(props.phone.phone || "", props.dataObject)
			);
		});

		const phoneLabel = computed(() => {
			return renderData(props.phone.label || "", props.dataObject) || "";
		});

		const phoneExt = computed(
			() =>
				renderData(props.phone.extension || "", props.dataObject) || ""
		);

		const hasExtension = computed(() => phoneExt.value !== "");

		const clickToCall = (phoneNumber, extension = "") => {
			const cleanPhoneNumber =
				phoneNumber && phoneNumber.replace(/[^0-9+]/g, "");
			const cleanExtension =
				extension && extension.replace(/[^0-9+]/g, "");
			if (cleanExtension)
				return `tel:${cleanPhoneNumber};${cleanExtension}`;
			return `tel:${cleanPhoneNumber}`;
		};

		const iconType = computed(() => {
			return props.phone.type === "Mobile" ? "mobile-phone" : "phone";
		});

		return {
			phoneLabel,
			clickToCall,
			phoneNumber,
			phoneExt,
			hasExtension,
			iconType,
		};
	},
};
