
import { computed } from "vue";
import CoreBlockFooterContactAddress from "./CoreBlockFooterContactAddress.vue";
import CoreBlockFooterContactPhone from "./CoreBlockFooterContactPhone";
import CoreBlockFooterContactEmail from "./CoreBlockFooterContactEmail";

export default {
	name: "CoreBlockFooterContact",
	components: {
		CoreBlockFooterContactAddress,
		CoreBlockFooterContactPhone,
		CoreBlockFooterContactEmail,
	},
	props: {
		contact: {
			type: Object,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
		type: {
			type: String,
			default: null,
			validator: (type) => ["contacts", "locations"].includes(type),
		},
		teamMemberId: {
			type: String,
			default: null,
		},
		isStacked: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const getContactFieldType = (field = {}) => {
			let fieldType;
			const types = [
				"officePhone",
				"altPhone",
				"altPhone2",
				"altPhone3",
				"mobile",
				"fax",
				"email",
				"address",
				"address2",
				"address3",
			];
			for (const key of types) {
				if (key in field) {
					fieldType = key;
					break;
				}
			}
			return fieldType;
		};

		const displayFieldType = (field) => {
			const fieldType = getContactFieldType(field);
			if (fieldType) {
				return { [`${fieldType}Display`]: field?.display ?? true };
			}
		};

		const flattenedContact = computed(() => {
			const { type1, type2, type3 } = props.contact;
			displayFieldType(type1);
			return {
				...type1,
				...displayFieldType(type1),
				...type2,
				...displayFieldType(type2),
				...type3,
				...displayFieldType(type3),
			};
		});

		const footer = computed(() => {
			return props.site.footer;
		});

		const teamMember = (teamMemberKey) => {
			const memberId = footer.value[teamMemberKey || props.teamMemberId];
			return props.site?.teamMembers?.find(
				(member) => memberId !== -1 && member.id === memberId
			);
		};

		const dataObject = computed(() => {
			if (teamMember(props.teamMemberId)) {
				return teamMember(props.teamMemberId);
			}
			return props.site;
		});

		const displayHeading = computed(() =>
			props.isStacked ? true : props.contact.displayTitle
		);

		const heading = computed(() => {
			if (props.isStacked) {
				if (props.type === "contacts") {
					return "Contact us";
				}
				if (props.type === "locations") {
					return "Our Locations";
				}
			}
			return props.contact.title;
		});

		const officePhone = computed(() => {
			if (!flattenedContact?.value?.officePhoneDisplay) return null;

			return {
				label: flattenedContact?.value?.officePhone?.label,
				phone: flattenedContact?.value?.officePhone?.phone,
				extension: flattenedContact?.value?.officePhone?.extension,
			};
		});

		const altPhone = computed(() => {
			if (!flattenedContact?.value?.altPhoneDisplay) return null;

			return {
				label: flattenedContact?.value?.altPhone?.label,
				phone: flattenedContact?.value?.altPhone?.phone,
				extension: flattenedContact?.value?.altPhone?.extension,
			};
		});

		const altPhone2 = computed(() => {
			if (!flattenedContact?.value?.altPhone2Display) return null;

			return {
				label: flattenedContact?.value?.altPhone2?.label,
				phone: flattenedContact?.value?.altPhone2?.phone,
				extension: flattenedContact?.value?.altPhone2?.extension,
			};
		});

		const altPhone3 = computed(() => {
			if (!flattenedContact?.value?.altPhone3Display) return null;

			return {
				label: flattenedContact?.value?.altPhone3?.label,
				phone: flattenedContact?.value?.altPhone3?.phone,
				extension: flattenedContact?.value?.altPhone3?.extension,
			};
		});

		const mobile = computed(() => {
			if (!flattenedContact.value.mobileDisplay) return null;
			return {
				...flattenedContact.value.mobile,
				type: "Mobile",
			};
		});

		const fax = computed(() => {
			if (!flattenedContact.value.faxDisplay) return null;
			return flattenedContact.value.fax;
		});

		const email = computed(() => {
			if (!flattenedContact.value.emailDisplay) return null;
			if (teamMember(props.teamMemberId)) {
				return teamMember(props.teamMemberId)?.emailAddress;
			}
			return flattenedContact.value.email;
		});

		const address = computed(() => {
			if (!flattenedContact?.value?.addressDisplay) return null;
			return {
				label: flattenedContact.value?.address?.label,
				streetAddress: flattenedContact.value?.address?.streetAddress,
				streetAddress2: flattenedContact.value?.address?.streetAddress2,
				city: flattenedContact.value?.address?.city,
				state: flattenedContact.value?.address?.state,
				zip: flattenedContact.value?.address?.zip,
			};
		});

		const address2 = computed(() => {
			if (!flattenedContact?.value?.address2Display) return null;
			return {
				label: flattenedContact.value?.address2?.label,
				streetAddress: flattenedContact.value?.address2?.streetAddress,
				streetAddress2:
					flattenedContact.value?.address2?.streetAddress2,
				city: flattenedContact.value?.address2?.city,
				state: flattenedContact.value?.address2?.state,
				zip: flattenedContact.value?.address2?.zip,
			};
		});

		const address3 = computed(() => {
			if (!flattenedContact?.value?.address3Display) return null;
			return {
				label: flattenedContact.value?.address3?.label,
				streetAddress: flattenedContact.value?.address3?.streetAddress,
				streetAddress2:
					flattenedContact.value?.address3?.streetAddress2,
				city: flattenedContact.value?.address3?.city,
				state: flattenedContact.value?.address3?.state,
				zip: flattenedContact.value?.address3?.zip,
			};
		});

		/**
		 * @returns {Object} An object containing addresses and contacts data.
		 * @description Merges properties from `type1`, `type2`, and `type3` for each contact location.
		 * Iterates through contact locations and merges properties from the specified types.
		 */
		const contactData = computed(() => {
			if (!props.isStacked) return { addresses: {}, contacts: {} };
			const types = ["type1", "type2", "type3"];
			const locations = [
				footer.value["contact-1"],
				footer.value["contact-2"],
				footer.value["contact-3"],
			];
			const addressObjects = {};
			const contactObjects = {};

			locations.forEach((location, index) => {
				const contactShow = Object.hasOwnProperty.call(
					footer.value,
					`contact-${index + 1}-show`
				)
					? footer.value[`contact-${index + 1}-show`]
					: true;
				if (location && contactShow) {
					const contactKey = `contact-${index + 1}`;
					const teamMemberKey = `team-member-${index + 1}`;
					const mergedProps = {};

					types.forEach((type) => {
						if (location[type]) {
							Object.assign(mergedProps, location[type]);
						}
					});

					const { address, ...contactProps } = mergedProps;
					if (address) {
						if (teamMember(teamMemberKey)) {
							const member = teamMember(teamMemberKey);
							addressObjects[contactKey] = {
								label: address.label,
								streetAddress: member.address,
								city: member.city,
								state: member.state,
								zip: member.postalCode,
							};
						} else {
							addressObjects[contactKey] = address;
						}
					}
					if (Object.keys(contactProps).length > 0) {
						if (teamMember(teamMemberKey)) {
							const member = teamMember(teamMemberKey);
							contactObjects[contactKey] = {
								...contactProps,
								officePhone: {
									label: contactProps.officePhone?.label,
									phone: member.phoneNumber,
								},
								email: member.emailAddress,
							};
						} else {
							contactObjects[contactKey] = contactProps;
						}
					}
				}
			});

			return { addresses: addressObjects, contacts: contactObjects };
		});

		return {
			flattenedContact,
			heading,
			displayHeading,
			officePhone,
			altPhone,
			altPhone2,
			altPhone3,
			mobile,
			fax,
			email,
			address,
			address2,
			address3,
			contactData,
			teamMember,
			dataObject,
		};
	},
};
